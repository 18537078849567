import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="नामानुभव ग्रंथ - पूर्णाहुती" />
        <HeaderGeneric title="नामानुभव ग्रंथ" adhyaay='पूर्णाहुती'/>
        <div id="main">
          <section id="content" className="main">
            <p>
गणामहाराज नामात । आनंद अनुभवात ।< br />
गण गण गणात बोतेत । महाराज तोषतात ॥< br />
तल्लीन होता नामात । ‘गुरू’ ‘गुरू’ दिसतात ।< br />
पांडुरंग लिहवतात । ओव्या रचल्या जातात ॥< br />
नामाचा भरवतो घास । गणामहाराज सर्वांस ।< br />
गुरूचरण आपणांस । लाभतील हमखास ॥< br />
कर्ता करविता भगवंत । गणामहाराज निमित्त्य ।< br />
स्वानुभवे वदतात । तराल तुम्ही नामात ॥< br />
आम्ही झालो नामाचे नामाचे । आता बोल निश्चयाचे ।< br />
रोज नाम भजायचे । त्यावाचून न झोपायचे ॥< br />
इतुक्या ओव्या वाचता । भयभीती न चित्ता ।< br />
अनुभवीन नाम आता । न भटकणार वृथा ॥< br />
< br />
आता नामाचा गजर करा । < br />
हरी नामाचा गजर करा ॥< br />
< br />
हरी हरी हरी हरी । वास कर तू माझ्या घरी ।< br />
भजन प्रत्येक प्रहरी । म्हणेन हरी हरी हरी हरी ॥< br />
< br />
विठ्ठल विठ्ठल बोला । जय हरी विठ्ठल बोला ।< br />
माझा विठ्ठल भोळा । रखुमाईसह घराला ॥< br />
< br />
दत्त दत्त बोला । गुरूदेव दत्त बोला ।< br />
श्रीपाद श्रीवल्लभ बोला । आनंदी आनंदे बोला ॥< br />
< br />
दिगंबरा दिगंबरा । श्रीपाद श्रीवल्लभ दिगंबरा ।< br />
भिक्षा मागा माझ्या घरा । दत्त वास माझ्या घरा ॥< br />
< br />
दत्ता दिगंबरा या हो । स्वामी मला भेट द्या हो ।< br />
शाश्वती मन रमू द्या हो । मला जीवनी उद्धरा हो ॥< br />
< br />
विश्वोद्धारक नाथ माझा । श्री रंग अवधूत ।< br />
दत्त दत्त भजण्यात । दिसे अवधूत ॥< br />
< br />
स्वामी समर्थ, स्वामी समर्थ । नाम राहू द्या मुखात ।< br />
आता भेटा क्षणार्धात । नका पाहू माझा अंत ॥< br />
< br />
वासुदेवानंदा सखा । तार माझ्या भव दुःखा ।< br />
दत्त नाम माझ्या मुखा । ठेव लेकरा हसतमुखा ॥< br />
< br />
विश्वोद्धारक श्रीकृष्णा । नाम छंद लागुद्या मना ।< br />
येता जाताना भजताना । तुलाच पाहीन श्रीकृष्णा ॥< br />
< br />
जय जय रामकृष्ण हरी । जय जय रामकृष्ण हरी ।< br />
भजेन तुम्हा नित्य प्रहरी । वास करा माझ्या घरी ॥< br />
< br />
रघुपती राघव राजाराम । पतीत पावन सीताराम ।< br />
येता जाता घेता नाम । सर्व स्थळी पाहीन राम ॥< br />
< br />
गजानन, गजानन । श्रीसमर्थ गजानन ।< br />
श्रीसमर्थ गजानन । श्रीसमर्थ गजानन ॥< br />
< br />
गोपाळा गोपाळा । गजानन बाबा गोपाळा ।< br />
साईबाबा गोपाळा । गजानन बाबा गोपाळा ॥< br />
< br />
ॐ नमो सद्‌गुरू । श्री गजानन बाबा ।< br />
श्री गजानन बाबा । श्री गजानन बाबा ॥< br />
< br />
पांडुरंग, पांडुरंग । माझे गुरू पांडुरंग ।< br />
नाम मुखी राहो रोज । लाज राखा माझी रोज ॥< br />
< br />
योगेश्वरा योगेश्वरा । माझा बंधु सखा खरा ॥< br />
वास करा माझ्या घरा । भवसागरी उद्धरा ॥< br />
< br />
योगेश्वरा झालो तुझा । तेणे प्रपंच झाला तुझा ॥< br />
नाम भजणे छंद माझा । दर्शनाचा योग माझा ॥< br />
< br />
योगेश्वरा, पांडुरंगा । तुम्ही जाणा अंतरंगा ॥< br />
जडो आता सत्संगा । शुद्ध भाव अंतरा ॥< br />
< br />
योगेश्वर माझा सखा । सदा माझा पाठीराखा ॥< br />
आता न उरे भवदुःखा । तरणार माझी नौका ॥< br />

</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
